<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("attendanceDevice.deviceName")
                }}</label>
                <input
                    v-model.trim="model.device_name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('device_name') }"
                    ref="device_name"
                />
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('device_name')"
                >
                    {{ errors.first("device_name") }}
                </div>
            </div>
        </div>

        <div class="col-md-12 tw-flex mb-3">
            <div class="col-md-6 tw-pr-2">
                <label class="form-col-label control-label required">{{
                    $t("attendanceDevice.deviceIp")
                }}</label>
                <input
                    v-model.trim="model.device_ip"
                    type="text"
                    placeholder="0.0.0.0"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('device_ip') }"
                />
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('device_ip')"
                >
                    {{ errors.first("device_ip") }}
                </div>
            </div>
            <div class="col-md-6 tw-pl-2">
                <label class="form-col-label control-label required">{{
                    $t("attendanceDevice.port")
                }}</label>
                <input
                    v-model.trim="model.port"
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('port') }"
                />
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('port')"
                >
                    {{ errors.first("port") }}
                </div>
            </div>
        </div>

        <div class="col-md-12 tw-flex mb-3">
            <div class="col-md-6 tw-pr-2">
                <label class="form-col-label control-label required">{{
                    $t("attendanceDevice.deviceType")
                }}</label>
                <Select
                    v-model="model.att_device_type"
                    :class="{
                        'ivu-form-item-error': errors.has('att_device_type')
                    }"
                >
                    <Option
                        v-for="(deviceType, index) in deviceTypes"
                        :key="index"
                        :value="deviceType.att_type_id"
                        >{{ deviceType.att_device_type }}
                    </Option>
                </Select>
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('att_device_type')"
                >
                    {{ errors.first("att_device_type") }}
                </div>
            </div>
            <div class="col-md-6 tw-pl-2">
                <label class="form-col-label control-label">{{
                    $t("attendanceDevice.sdkVersion")
                }}</label>
                <input
                    v-model.trim="model.sdk_version"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>

        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("attendanceDevice.remarks")
                }}</label>
                <textarea
                    v-model.trim="model.remarks"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>

        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    :disabled="waiting_new || waiting"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "attendance-deviceForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                device_name: null,
                device_ip: null,
                port: null,
                att_device_type: null,
                sdk_version: null,
                remarks: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/attendanceDevice", [
            "edit_data",
            "deviceTypes"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        ...mapActions("humanResource/attendanceDevice", ["getDeviceType"]),
        async fetchResource() {
            this.loading = true;
            await this.getDeviceType();
            this.loading = false;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/attendanceDevice/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/attendanceDevice/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/attendanceDevice/update", {
                    id: this.edit_data.device_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.fetchResource();
            this.$nextTick(function() {
                this.$refs.device_name.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.device_name = this.edit_data.device_name;
                this.model.device_ip = this.edit_data.device_ip;
                this.model.port = this.edit_data.port;
                this.model.att_device_type = this.edit_data.att_device_type.att_type_id;
                this.model.sdk_version = this.edit_data.sdk_version;
                this.model.remarks = this.edit_data.remarks;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.device_name = null;
            this.model.device_ip = null;
            this.model.port = null;
            this.model.att_device_type = null;
            this.model.sdk_version = null;
            this.model.remarks = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "ATTENDANCE DEVICE",
                desc: not.text
            });
        }
    }
};
</script>
